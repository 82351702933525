





















import { BCard } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({ components: { VueApexCharts, BCard } })
export default class MetricsCard extends Vue {
	@Prop({ default: () => ({ options: {}, series: [] }) }) chartDefinition: any;
}
